<template>
  <div>
    <c-table
      title="LBLBASEINFO"
      class="q-mb-md"
      :columns="grid.columns"
      :data="grid.data"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      :isTitle="true"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="survey"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveSurvey"
            @btnCallback="saveSurveyCallback" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="survey"
            mappingType="PUT"
            label="설문완료" 
            icon="check"
            @beforeAction="completeSurvey"
            @btnCallback="completeSurveyCallback" />
          <c-btn 
            v-show="cancelBtnEditable" 
            :isSubmit="isCancel"
            :url="cancelUrl"
            :param="survey"
            mappingType="PUT"
            label="다시작성" 
            icon="keyboard_return"
            @beforeAction="cancelSurvey"
            @btnCallback="cancelSurveyCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              이름
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{survey.userName}}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              연령
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <c-text
              disabled
              :editable="editable"
              type="number"
              label=""
              name="age"
              v-model="survey.age">
            </c-text>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              성별
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <c-radio
              disabled
              :editable="editable"
              codeGroupCd="SEX_CD"
              label=""
              name="sexCd"
              v-model="survey.sexCd">
            </c-radio>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              현직장경력
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <c-text
              :disabled="true"
              :editable="editable"
              label=""
              name="longevityCount"
              v-model="survey.longevityCount">
            </c-text>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              작업부서
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-2"
            :rowspan="1" :colspan="5">
            <span class="custom-text-before-parent">
              <!-- {{survey.deptName}} 
              <b>부</b>  -->
              <c-text
                :disabled="true"
                :editable="editable"
                beforeText="부"
                label=""
                name="deptName"
                :value="survey.upDeptName ? survey.upDeptName + ' / ' + survey.deptName : survey.deptName">
              </c-text>
              <c-text
                :disabled="disabled"
                :editable="editable"
                beforeText="라인"
                label=""
                name="deptLine"
                v-model="survey.deptLine">
              </c-text>
              <c-text
                :disabled="disabled"
                :editable="editable"
                beforeText="수행작업"
                label=""
                name="deptWork"
                v-model="survey.deptWork">
              </c-text>
              
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              결혼여부
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <c-radio
              :disabled="disabled"
              :editable="editable"
              codeGroupCd="MARRIAGE_CD"
              label=""
              name="marriageFlag"
              v-model="survey.marriageFlag">
            </c-radio>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===2" no-hover>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              현재작업<br/>(구체적으로)
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-3"
            :rowspan="1" :colspan="7">
            <span class="custom-text-before-parent">
              <c-text
                :disabled="disabled"
                :editable="editable"
                beforeText="작업내용"
                label=""
                name="curWorkContents"
                v-model="survey.curWorkContents">
              </c-text>
              <c-text
                :disabled="disabled"
                :editable="editable"
                beforeText="작업기간"
                label=""
                name="curWorkRange"
                v-model="survey.curWorkRange">
              </c-text>
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===3" no-hover>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              1일 근무시간
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-3"
            :rowspan="1" :colspan="7">
            <span class="custom-text-before-parent">
              <c-text
                style="width:20% !important;display: inline-block;float: left;"
                :disabled="disabled"
                :editable="editable"
                type="number"
                beforeText="시간"
                label=""
                name="onedayWorkTimeHour"
                v-model="survey.onedayWorkTimeHour">
              </c-text>
              <q-chip style="float: left;top:2px;margin-right: 2px !important;"  
                square color="orange" text-color="white">
                근무 중 휴식시간(식사시간 제외)
              </q-chip>
              <c-text
                style="width:20% !important;display: inline-block;float: left;"
                :disabled="disabled"
                :editable="editable"
                type="number"
                beforeText="분"
                label=""
                name="onedayWorkTimeRestMin"
                v-model="survey.onedayWorkTimeRestMin">
              </c-text>
              <c-text
                style="width:20% !important;display: inline-block;float: left;"
                :disabled="disabled"
                :editable="editable"
                type="number"
                beforeText="휴식 횟수"
                label=""
                name="onedayWorkTimeRestCnt"
                v-model="survey.onedayWorkTimeRestCnt">
              </c-text>
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===4" no-hover>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              현작업 전에 했던 작업
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-3"
            :rowspan="1" :colspan="7">
            <span class="custom-text-before-parent">
              <c-text
                :disabled="disabled"
                :editable="editable"
                beforeText="작업내용"
                label=""
                name="pastWorkContents"
                v-model="survey.pastWorkContents">
              </c-text>
              <c-text
                :disabled="disabled"
                :editable="editable"
                beforeText="작업기간"
                label=""
                name="pastWorkRange"
                v-model="survey.pastWorkRange">
              </c-text>
            </span>
          </q-td>
        </q-tr>
      </template>
    </c-table>
    <q-form ref="editForm">
      <c-card v-if="checkboxItems && checkboxItems.length > 0" class="cardClassDetailForm" title="체크리스트">
        <template slot="card-detail">
          <div class="col-12" v-for="(data, idx) in checkboxItems" :key="idx">
            <template v-if="data.heaMuscleWorkerSurveyChecklistId === 'MSC0000020'">
              <c-text
                :disabled="disabled || !(isPainEffectChecklist(data) ? isWhenPainSixMonthCheck : true)"
                :editable="editable"
                :label="data.checklistName"
                :name="`value${idx}`"
                v-model="data.value"
              />
            </template>
            <template v-else-if="data.heaMuscleWorkerSurveyChecklistId !== 'MSC0000013' && data.heaMuscleWorkerSurveyChecklistId !== 'MSC0000019'">
              <c-radio
                :required="isUnderChecklist(data) ? isWhenPain3Check : (isPainEffectChecklist(data) ? isWhenPainSixMonthCheck : true)"
                :disabled="disabled || !(isUnderChecklist(data) ? isWhenPain3Check : (isPainEffectChecklist(data) ? isWhenPainSixMonthCheck : true))"
                :editable="editable"
                :isArray="false"
                :comboItems="data.inquiries"
                itemText="inquiryName"
                itemValue="heaMuscleWorkerSurveyInquiryId"
                valueText="inquiryName"
                valueKey="heaMuscleWorkerSurveyInquiryId"
                :label="data.checklistName"
                :name="`value${idx}`"
                v-model="data.value"
              >
              </c-radio>
              <template v-if="data.heaMuscleWorkerSurveyChecklistId === 'MSC0000009'">
                <q-slide-transition>
                  <div v-show="data.value === 'MSI0000043'" style="margin-top: -35px;">
                    <font class="symptomSurveyAdditional">'예'인 경우 현재 상태는?</font>
                    <c-radio
                      :required="data.value === 'MSI0000043'"
                      :disabled="disabled || data.value !== 'MSI0000043'"
                      :editable="editable"
                      :isArray="false"
                      :comboItems="diseaseDiagnosisLapseItems"
                      itemText="codeName"
                      itemValue="code"
                      valueText="codeName"
                      valueKey="code"
                      class="symptomSurveyLabel"
                      style="display:inline-block"
                      label=""
                      name="diseaseDiagnosisLapseCd"
                      v-model="survey.diseaseDiagnosisLapseCd"
                    >
                    </c-radio>
                    <br/>
                    <font class="symptomSurveyAdditional">보기 : </font>
                    <c-checkbox
                      noborder
                      :required="data.value === 'MSI0000043'"
                      :disabled="disabled || data.value !== 'MSI0000043'"
                      :editable="editable"
                      :isArray="false"
                      :comboItems="diseaseDiagnosisItems"
                      itemText="codeName"
                      itemValue="code"
                      valueText="codeName"
                      valueKey="code"
                      style="display:inline-block"
                      label=""
                      name="diseaseDiagnosisCd"
                      v-model="survey.diseaseDiagnosisCd"
                    />
                  </div>
                </q-slide-transition>
              </template>
              <template v-else-if="data.heaMuscleWorkerSurveyChecklistId === 'MSC0000010'">
                <q-slide-transition>
                  <div v-show="data.value === 'MSI0000045'" style="margin-top: -35px;">
                    <font class="symptomSurveyAdditional">'예'인 경우 상해 부위는? </font>
                    <c-checkbox
                      noborder
                      :required="data.value === 'MSI0000045'"
                      :disabled="disabled || data.value !== 'MSI0000045'"
                      :editable="editable"
                      :isArray="false"
                      :comboItems="injuryTreatmentAreaItems"
                      itemText="codeName"
                      itemValue="code"
                      valueText="codeName"
                      valueKey="code"
                      style="display:inline-block"
                      label=""
                      name="injuryTreatmentAreaCd"
                      v-model="survey.injuryTreatmentAreaCd"
                    />
                  </div>
                </q-slide-transition>
              </template>
              <template v-else-if="data.heaMuscleWorkerSurveyChecklistId === 'MSC0000011'">
                <q-slide-transition>
                  <div v-show="data.value === 'MSI0000047'" style="margin-top: -35px;">
                    <font class="symptomSurveyAdditional">'예'인 경우 상해 부위는? </font>
                    <c-checkbox
                      noborder
                      :required="data.value === 'MSI0000047'"
                      :disabled="disabled || data.value !== 'MSI0000047'"
                      :editable="editable"
                      :isArray="false"
                      :comboItems="accidentInjuryAreaItems"
                      itemText="codeName"
                      itemValue="code"
                      valueText="codeName"
                      valueKey="code"
                      style="display:inline-block"
                      label=""
                      name="accidentInjuryAreaCd"
                      v-model="survey.accidentInjuryAreaCd"
                    />
                  </div>
                </q-slide-transition>
              </template>
            </template>
            <template v-else>
              <c-checkbox
                noborder
                :required="isPainEffectChecklist(data) ? isWhenPainSixMonthCheck : true"
                :disabled="disabled || !(isPainEffectChecklist(data) ? isWhenPainSixMonthCheck : true)"
                :editable="editable"
                :isArray="false"
                :comboItems="data.inquiries"
                itemText="inquiryName"
                itemValue="heaMuscleWorkerSurveyInquiryId"
                valueText="inquiryName"
                valueKey="heaMuscleWorkerSurveyInquiryId"
                :label="data.checklistName"
                :name="`value${idx}`"
                v-model="data.value"
              />
            </template>
            <template v-if="data.heaMuscleWorkerSurveyChecklistId === 'MSC0000012'">
              <div class="row" style="margin-bottom: 20px !important;">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-table
                    ref="table"
                    title="통증부위 및 그에 따른 통증점수"
                    :columns="gridPainArea.columns"
                    :gridHeight="gridPainArea.height"
                    :data="survey.painAreas"
                    :filtering="false"
                    :columnSetting="false"
                    :usePaging="false"
                    :editable="editable&&!disabled&&data.value==='MSI0000050'"
                    selection="multiple"
                    rowKey="ramAssessmentTeamId"
                  >
                    <!-- 버튼 영역 -->
                    <template slot="table-button">
                      <q-btn-group outline >
                        <c-btn 
                          label="LBLADD" 
                          icon="add" 
                          @btnClicked="addPainArea" />
                        <c-btn 
                          v-if="editable&&!disabled&&data.value==='MSI0000050'" 
                          :disabled="!survey.painAreas || survey.painAreas.length === 0"
                          label="제외" 
                          icon="remove" 
                          @btnClicked="deletePainArea" />
                      </q-btn-group>
                    </template>
                    <template v-slot:customArea="{ props, col }">
                      <template v-if="col.name==='painPerceptionScale'">
                        <q-slider
                          style="margin: 0 10px;width: 90%;"
                          class="q-mt-xl"
                          color="teal"
                          thumb-color="teal"
                          snap
                          :min="0"
                          :max="10"
                          :step="1"
                          markers
                          marker-labels
                          v-model="props.row.painPerceptionScale"
                          @change="changePainArea(props.row)"
                        >
                        </q-slider>
                      </template>
                      <template v-else-if="col.name==='face'">
                        <q-img
                          v-if="props.row.painPerceptionScale >= 0 && props.row.painPerceptionScale < 2"
                          :src="require(`@/assets/images/painPerceptionScale_0.png`)"
                          spinner-color="white"
                          style="height: 60px; width: 55px;"
                        />
                        <q-img
                          v-if="props.row.painPerceptionScale >= 2 && props.row.painPerceptionScale < 4"
                          :src="require(`@/assets/images/painPerceptionScale_2.png`)"
                          spinner-color="white"
                          style="height: 60px; width: 55px;"
                        />
                        <q-img
                          v-if="props.row.painPerceptionScale >= 4 && props.row.painPerceptionScale < 6"
                          :src="require(`@/assets/images/painPerceptionScale_4.png`)"
                          spinner-color="white"
                          style="height: 60px; width: 55px;"
                        />
                        <q-img
                          v-if="props.row.painPerceptionScale >= 6 && props.row.painPerceptionScale < 8"
                          :src="require(`@/assets/images/painPerceptionScale_6.png`)"
                          spinner-color="white"
                          style="height: 60px; width: 55px;"
                        />
                        <q-img
                          v-if="props.row.painPerceptionScale >= 8 && props.row.painPerceptionScale < 10"
                          :src="require(`@/assets/images/painPerceptionScale_8.png`)"
                          spinner-color="white"
                          style="height: 60px; width: 55px;"
                        />
                        <q-img
                          v-if="props.row.painPerceptionScale === 10"
                          :src="require(`@/assets/images/painPerceptionScale_10.png`)"
                          spinner-color="white"
                          style="height: 60px; width: 55px;"
                        />

                      </template>
                    </template>
                  </c-table>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <c-card title="[그림1] 통증부위" class="cardClassDetailForm symptomSurveyPainCard">
                    <template slot="card-detail">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="text-align: center;margin-bottom: 10px;">
                        <q-img 
                          :src="require('@/assets/images/symptomSurveyPainArea.jpg')"
                          :ratio="1.2"
                          style="max-width: 370px;"
                        />
                      </div>
                    </template>
                  </c-card>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                  <c-card title="[그림2] 통증점수" class="cardClassDetailForm symptomSurveyPainCard">
                    <template slot="card-detail">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 q-p-sm">
                        <q-list dense>
                          <q-item>
                            <q-item-section>
                              <q-slider
                                readonly
                                class="q-mt-xl"
                                color="teal"
                                thumb-color="teal"
                                thumb-size="30px"
                                track-size="10px"
                                snap
                                :min="0"
                                :max="10"
                                :step="1"
                                label
                                label-always
                                markers
                                marker-labels
                                v-model="painPerceptionScale"
                              >
                                <template v-slot:marker-label-group="{ markerList }">
                                  <div
                                    v-for="val in [1, 2, 3, 4, 6, 7, 8, 9]"
                                    :key="val"
                                    class="cursor-pointer"
                                    :class="markerList[val].classes"
                                    :style="markerList[val].style"
                                    @click="painPerceptionScale = val"
                                  >{{ val }}</div>
                                  <div
                                    :class="markerList[0].classes"
                                    :style="markerList[0].style"
                                    style="text-align: center;width:50px"
                                    size="sm"
                                    color="orange"
                                    @click="painPerceptionScale = 0"
                                  >
                                    0<br/>통증<br/>없음
                                  </div>
                                  <div
                                    :class="markerList[5].classes"
                                    :style="markerList[5].style"
                                    style="text-align: center;width:50px"
                                    size="sm"
                                    color="orange"
                                    @click="painPerceptionScale = 5"
                                  >
                                    5<br/>증감도<br/>통증
                                  </div>
                                  <div
                                    :class="markerList[10].classes"
                                    :style="markerList[10].style"
                                    style="text-align: center;width:50px"
                                    size="sm"
                                    color="orange"
                                    @click="painPerceptionScale = 10"
                                  >
                                    10<br/>심한<br/>통증
                                  </div>
                                </template>
                              </q-slider>
                            </q-item-section>
                          </q-item>
                        </q-list>
                        <div style="text-align: center;padding-top: 55px;padding-bottom: 15px;">
                          <q-img
                            :src="require(`@/assets/images/painPerceptionScale_0${painPerceptionScale >= 0 && painPerceptionScale < 2 ? '' : '_w'}.png`)"
                            spinner-color="white"
                            style="height: 8%; width: 7%; margin: 10px 4%;"
                          />
                          <q-img
                            :src="require(`@/assets/images/painPerceptionScale_2${painPerceptionScale >= 2 && painPerceptionScale < 4 ? '' : '_w'}.png`)"
                            spinner-color="white"
                            style="height: 8%; width: 7%; margin: 10px 4%;"
                          />
                          <q-img
                            :src="require(`@/assets/images/painPerceptionScale_4${painPerceptionScale >= 4 && painPerceptionScale < 6 ? '' : '_w'}.png`)"
                            spinner-color="white"
                            style="height: 8%; width: 7%; margin: 10px 4%;"
                          />
                          <q-img
                            :src="require(`@/assets/images/painPerceptionScale_6${painPerceptionScale >= 6 && painPerceptionScale < 8 ? '' : '_w'}.png`)"
                            spinner-color="white"
                            style="height: 8%; width: 7%; margin: 10px 4%;"
                          />
                          <q-img
                            :src="require(`@/assets/images/painPerceptionScale_8${painPerceptionScale >= 8 && painPerceptionScale < 10 ? '' : '_w'}.png`)"
                            spinner-color="white"
                            style="height: 8%; width: 7%; margin: 10px 4%;"
                          />
                          <q-img
                            :src="require(`@/assets/images/painPerceptionScale_10${painPerceptionScale === 10 ? '' : '_w'}.png`)"
                            spinner-color="white"
                            style="height: 8%; width: 7%; margin: 10px 4%;"
                          />
                        </div>
                      </div>
                    </template>
                  </c-card>
                </div>
              </div>
            </template>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'symptomSurveyDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaMuscleWorkerSurveyId: '',
        surveies: [],
        readOnly: false,
      }),
    },
    tabSurvey: {
      type: Object,
      default: () => ({
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      survey: {
        heaMuscleWorkerSurveyId: '',  // 근골격계 단위작업 작업자 설문 일련번호
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        heaMuscleSurveyStepCd: '',  // 설문현황
        userId: '',  // 유저 시퀀스
        userName: '',  // 사용자명
        deptName: '',  // 부서명
        upDeptName: '',  // 부서명
        age: '',  // 연령
        longevityCount: '',  // 현직장경력
        sexCd: '',  // 성별
        deptLine: '',  // 작업부서 라인
        deptWork: '',  // 작업부서 수행작업
        marriageFlag: '',  // 결혼여부
        curWorkContents: '',  // 현재작업 - 작업내용
        curWorkRange: '',  // 현재작업 - 작업기간
        onedayWorkTimeHour: '',  // 1일 근무시간 - 시간
        onedayWorkTimeRestMin: '',  // 1일 근무시간 - 휴식 - 분
        onedayWorkTimeRestCnt: '',  // 1일 근무시간 - 휴식 - 횟수
        pastWorkContents: '',  // 현작업 전에 했던 작업 - 작업내용
        pastWorkRange: '',  // 현작업 전에 했던 작업 - 작업기간
        checklsit1: '',  // 체크리스트 1
        checklsit2: '',  // 체크리스트 2
        checklsit3: '',  // 체크리스트 3
        checklsit4: '',  // 체크리스트 4
        checklsit5: '',  // 체크리스트 5
        checklsit6: '',  // 체크리스트 6 (정기, 매년)
        checklsit7: '',  // 체크리스트 7 (정기, 매년)
        checklsit8: '',  // 체크리스트 8 (정기, 매년)
        checklsit9: '',  // 체크리스트 9 (정기, 매년)
        checklsit10: '',  // 체크리스트 10 (정기, 매년)
        checklsit11: '',  // 체크리스트 11 (정기, 매년)
        checklsit12: '',  // 체크리스트 12 (정기, 매년)
        checklsit13: '',  // 체크리스트 13 (정기, 매년)
        checklsit14: '',  // 체크리스트 14 (정기, 매년)
        diseaseDiagnosisCd: '',  // 질병 진단 (체크리스트 4번 항목)
        diseaseDiagnosisLapseCd: '',  // 질병 진단 경과 (체크리스트 4번 항목)
        injuryTreatmentAreaCd: '',  // 상해 시술 부위
        accidentInjuryAreaCd: '',  // 사고 상해 부위
        lastQue: '',  // 통증부위 설문여부
        neck: '',  // 목
        neck2: '',  // 목 - 2
        neck3: '',  // 목 - 3
        neck4: '',  // 목 - 4
        neck5: '',  // 목 - 5
        neck6: '',  // 목 - 6
        neck6etc: '',  // 목 - 6기타
        shoulder: '',  // 어깨
        shoulder1: '',  // 어깨 - 1
        shoulder2: '',  // 어깨 - 2
        shoulder3: '',  // 어깨 - 3
        shoulder4: '',  // 어깨 - 4
        shoulder5: '',  // 어깨 - 5
        shoulder6: '',  // 어깨 - 6
        shoulder6etc: '',  // 어깨 - 6기타
        arm: '',  // 팔/팔꿈치
        arm1: '',  // 팔/팔꿈치 - 1
        arm2: '',  // 팔/팔꿈치 - 2
        arm3: '',  // 팔/팔꿈치 - 3
        arm4: '',  // 팔/팔꿈치 - 4
        arm5: '',  // 팔/팔꿈치 - 5
        arm6: '',  // 팔/팔꿈치 - 6
        arm6etc: '',  // 팔/팔꿈치 - 6기타
        hand: '',  // 손/손목/손가락
        hand1: '',  // 손/손목/손가락 - 1
        hand2: '',  // 손/손목/손가락 - 2
        hand3: '',  // 손/손목/손가락 - 3
        hand4: '',  // 손/손목/손가락 - 4
        hand5: '',  // 손/손목/손가락 - 5
        hand6: '',  // 손/손목/손가락 - 6
        hand6etc: '',  // 손/손목/손가락 - 6기타
        waist: '',  // 허리
        waist2: '',  // 허리 - 2
        waist3: '',  // 허리 - 3
        waist4: '',  // 허리 - 4
        waist5: '',  // 허리 - 5
        waist6: '',  // 허리 - 6
        waist6etc: '',  // 허리 - 6기타
        leg: '',  // 다리/발
        leg1: '',  // 다리/발 - 1
        leg2: '',  // 다리/발 - 2
        leg3: '',  // 다리/발 - 3
        leg4: '',  // 다리/발 - 4
        leg5: '',  // 다리/발 - 5
        leg6: '',  // 다리/발 - 6
        leg6etc: '',  // 다리/발 - 6기타
        painAreas: [],  // 통증부위
        painPerceptionScale: 0,  // 통증 자각 척도
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      painPerceptionScale: 7,  // 통증 자각 척도
      grid: {
        columns: [
          {
            name: 'header1',
            field: 'header1',
            label: 'header1',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data1',
            field: 'data1',
            label: 'data1',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header2',
            field: 'header2',
            label: 'header2',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data2',
            field: 'data2',
            label: 'data2',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header3',
            field: 'header3',
            label: 'header3',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data3',
            field: 'data3',
            label: 'data3',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header4',
            field: 'header4',
            label: 'header4',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data4',
            field: 'data4',
            label: 'data4',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
        ],
        data: [{}, {}, {}, {}, {},],
      },
      gridPainArea: {
        columns: [
          {
            name: 'heaSurveyPainAreaName',
            field: 'heaSurveyPainAreaName',
            label: '통증부위',
            align: 'center',
            sortable: false,
            style: 'width:10%',
          },
          {
            name: 'painPerceptionScale',
            field: 'painPerceptionScale',
            label: '통증 자각 척도',
            align: 'center',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'face',
            field: 'face',
            label: '통증표시',
            align: 'center',
            sortable: false,
            style: 'width:10%',
            type: 'custom'
          },
        ],
        height: '400px',
      },
      checkboxItems: [],
      diseaseDiagnosisItems: [],
      diseaseDiagnosisLapseItems: [],
      injuryTreatmentAreaItems: [],
      accidentInjuryAreaItems: [],
      editable: true,
      isSave: false,
      isComplete: false,
      isCancel: false,
      detailUrl: '',
      checklistUrl: '',
      painAreaUrl: '',
      saveUrl: '',
      completeUrl: '',
      cancelUrl: '',
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
      return this.survey.heaMuscleSurveyStepCd === 'MS00000005' || this.popupParam.readOnly;
    },
    cancelBtnEditable() {
      return false;
    },
    isWhenPain3Check() {
      let check = false;
      if (this.checkboxItems && this.checkboxItems.length > 0) {
        let whenPain = this.$_.find(this.checkboxItems, { heaMuscleWorkerSurveyChecklistId: 'MSC0000013' })
        check = whenPain.value && whenPain.value.indexOf('MSI0000053') > -1
      }
      return check;
    },
    isWhenPainSixMonthCheck() {
      let check = false;
      if (this.checkboxItems && this.checkboxItems.length > 0) {
        let whenPain = this.$_.find(this.checkboxItems, { heaMuscleWorkerSurveyChecklistId: 'MSC0000012' })
        check = whenPain.value && whenPain.value.indexOf('MSI0000050') > -1
      }
      return check;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.muscleSystem.muscleSurvey.get.url
      this.checklistUrl = selectConfig.hea.muscleSystem.surveyChecklist.list.url
      this.painAreaUrl = selectConfig.hea.muscleSystem.painArea.list.url
      this.saveUrl = transactionConfig.hea.muscleSystem.muscleSurvey.update.url
      this.completeUrl = transactionConfig.hea.muscleSystem.muscleSurvey.complete.url
      this.cancelUrl = transactionConfig.hea.muscleSystem.muscleSurvey.cancel.url
      // code setting
      this.$comm.getMultiComboItems([
        'DISEASE_DIAGNOSIS_LAPSE_CD',
        'DISEASE_DIAGNOSIS_CD',
        'INJURY_TREATMENT_AREA_CD',
        'ACCIDENT_INJURY_AREA_CD',
      ]).then(_result => {
        this.diseaseDiagnosisLapseItems = _result['DISEASE_DIAGNOSIS_LAPSE_CD'];
        this.diseaseDiagnosisItems = _result['DISEASE_DIAGNOSIS_CD'];
        this.injuryTreatmentAreaItems = _result['INJURY_TREATMENT_AREA_CD'];
        this.accidentInjuryAreaItems = _result['ACCIDENT_INJURY_AREA_CD'];
      });
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.heaMuscleWorkerSurveyId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.survey, _result.data)
        // 체크리스트
        this.getChecklist();
      },);
    },
    getChecklist() {
      this.$http.url = this.checklistUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.survey.plantCd,
        heaSurveyCategoryCd: 'MSC0000001',
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        /**
         * 체크리스트 처리
         * checkbox형태로 for문으로 형태가 그려짐에 따라 v-model선정처리가 필요
         */
        if (_result.data && _result.data.length > 0) {
          let _idx = 1;
          this.$_.forEach(_result.data, item => {
            item.value = this.survey[`checklsit${_idx}`]
            _idx++;
          })
          this.checkboxItems = _result.data
        } else {
          this.checkboxItems = []
        }
      },);
    },
    isUnderChecklist(data) {
      return data.heaMuscleWorkerSurveyChecklistId === 'MSC0000014'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000015'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000016'
    },
    isPainEffectChecklist(data) {
      return data.heaMuscleWorkerSurveyChecklistId === 'MSC0000013'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000014'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000015'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000016'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000017'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000018'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000019'
        || data.heaMuscleWorkerSurveyChecklistId === 'MSC0000020'
    },
    changePainArea(row) {
      if (row.editFlag !== 'C') {
        row.editFlag = 'U'
        row.chgUserId = this.$store.getters.user.userId
      }
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.survey.regUserId = this.$store.getters.user.userId
        this.survey.chgUserId = this.$store.getters.user.userId

        // 체크리스트
        if (this.checkboxItems && this.checkboxItems.length > 0) {
          let _idx = 1;
          this.$_.forEach(this.checkboxItems, item => {
            this.$set(this.survey, `checklsit${_idx}`, item.value)
            _idx++;
          })
        } else {
          this.$set(this.survey, `checklsit1`, '')
          this.$set(this.survey, `checklsit2`, '')
          this.$set(this.survey, `checklsit3`, '')
          this.$set(this.survey, `checklsit4`, '')
          this.$set(this.survey, `checklsit5`, '')
          this.$set(this.survey, `checklsit6`, '')
          this.$set(this.survey, `checklsit7`, '')
          this.$set(this.survey, `checklsit8`, '')
          this.$set(this.survey, `checklsit9`, '')
          this.$set(this.survey, `checklsit10`, '')
          this.$set(this.survey, `checklsit11`, '')
          this.$set(this.survey, `checklsit12`, '')
          this.$set(this.survey, `checklsit13`, '')
          this.$set(this.survey, `checklsit14`, '')
        }
        resolve(true);
      });
    },
    addPainArea() {
      this.popupOptions.title = '통증부위 검색';
      this.popupOptions.param = {
        type: 'multiple',
        codeGroupCd: 'HEA_SURVEY_PAIN_AREA_CD'
      };
      this.popupOptions.target = () => import(`${'@/pages/sys/code/codePop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePainAreaPopup;
    },
    closePainAreaPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.survey.painAreas, { heaSurveyPainAreaCd: item.code, }) === -1) {
            this.survey.painAreas.splice(0, 0, {
              heaMuscleWorkerSurveyPainAreaRegularId: uid(),  // 근골격계 정기/매년 설문지 - 통증부위 & 통증자각척도 일련번호
              heaMuscleWorkerSurveyId: this.popupParam.heaMuscleWorkerSurveyId,  // 근골격계 단위작업 작업자 설문 일련번호
              heaInvestigationPlanId: this.survey.heaMuscleWorkerSurveyId,  // 근골격계 조사계획 일련번호
              heaSurveyPainAreaCd: item.code,  // 통증부위 코드
              heaSurveyPainAreaName: item.codeName,  // 통증부위 코드
              painPerceptionScale: 5,  // 통증점수
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId
            })
          }
        })
      }
    },
    deletePainArea() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.survey.painAreas = this.$_.reject(this.survey.painAreas, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    completeValue() {
      return new Promise(resolve => {
        this.isComplete = !this.isComplete
        resolve(true);
      });
    },
    cancelValue() {
      return new Promise(resolve => {
        this.isCancel = !this.isCancel
        resolve(true);
      });
    },
    saveSurvey() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.saveValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeSurvey() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '설문완료 하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.completeValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    cancelSurvey() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '다시작성 하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.cancelValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    cancelSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>
<style lang="sass">
.th-td-style
  vertical-align: middle !important

.th-style
  background: #EEEEEE !important
  font-weight: 700

.th-style-1
  width: 10%

.td-style-1
  width: 15%

.td-style-2
  width: 65%

.td-style-3
  width: 90%

.custom-text-before-parent
  .custom-text-before
    font-size: 12px !important
    height: 22px !important
    min-height: 22px !important
    line-height: 22px
  .q-field--dense .q-field__inner
    padding-bottom: 0 !important
    padding-top: 3px !important
  .customText.q-field--dense .q-field__control, .customText.q-field--dense .q-field__marginal
    height: 22px !important
    min-height: 22px !important

.lastQue
  label
    padding-bottom: 0px !important

.symptomSurveyLabel
  label
    padding: 0 !important
    .q-field__inner
      padding: 0 !important
    .q-field__native
      padding: 0 !important

.symptomSurveyAdditional
  font-weight: 700

.symptomSurveyPainCard
  .customCardbody
    padding-top: 0 !important
    margin-top: 0 !important
</style>